import request from '@/utils/request'
const serivceBase = require('@/config/serviceConfig')

// 快捷同步链接兑换码-校验接口
export function checkForSyncLink(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeStream/checkForSyncLink`,
    method: 'post',
    data
  })
}
// 快捷同步兑换
export function quickSyncVerify(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeStream/quickSyncVerify`,
    method: 'post',
    data
  })
}
// 同步链接兑换码-兑换结果列表查询
export function quickSyncList(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeStream/listRedeemCodeForSyncLink`,
    method: 'post',
    data
  })
}
// 同步链接兑换码-记录操作记录
export function addRecord(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeOperateRecord/addOperateRecord`,
    method: 'post',
    data
  })
}
