<template>
  <div class="wrapper">
    <div class="wrapper-good">
      <img :src="checkData.imageUrl" />
      <div class="line" />
      <div class="couponStatus" v-if="!list.length">
        <div class="fail" v-if="[95899].includes(couponStatus)">
          <img src="@/assets/img/quickSync/fail.png" />
          <p>系统繁忙，请您稍后再试</p>
        </div>
        <template v-else>
          <!-- 4:已冻结，5：已使用，6：已过期 -->
          <div class="expire" v-if="[4, 5, 6].includes(couponStatus)">
            {{
              [4].includes(couponStatus)
                ? '已冻结'
                : [5].includes(couponStatus)
                ? '已使用'
                : '已过期'
            }}
          </div>
          <van-button
            :class="[{ active: [4, 5, 6].includes(couponStatus) }, 'buttons']"
            :disabled="[6].includes(couponStatus)"
            :loading="loading"
            loading-text="查看中..."
            @click="doExchange()"
          >
            查看券码
          </van-button>
          <p>
            有效期至：
            {{ common.formatDate(checkData.effectiveEndTime, '{y}-{m}-{d}') }}
          </p>
        </template>
      </div>
      <ul v-if="list.length">
        <li class="list" v-for="item in list" :key="item.id">
          <!-- gcShape 0：卡号卡密 1：卡密 2：卡号短链 3：卡号短链验证码 -->
          <template v-if="[0].includes(item.gcShape)">
            <div class="item">
              <div class="label">卡号：{{ item.cardNo }}</div>
              <div
                class="btn"
                v-clipboard:copy="item.cardNo"
                v-clipboard:success="copySuccess"
                v-clipboard:error="copyError"
              >
                复制
              </div>
            </div>
            <div class="item">
              <div class="label">密码：{{ item.cardPwd }}</div>
              <div
                class="btn"
                v-clipboard:copy="item.cardPwd"
                v-clipboard:success="copySuccess"
                v-clipboard:error="copyError"
              >
                复制
              </div>
            </div>
          </template>
          <div class="item" v-if="[1].includes(item.gcShape)">
            <div class="label">卡密：{{ item.cardPwd }}</div>
            <div
              class="btn"
              v-clipboard:copy="item.cardPwd"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
            >
              复制
            </div>
          </div>
          <div class="item" v-if="[2].includes(item.gcShape)">
            <div class="label">
              短链：
              <a class="text" :href="item.shortUrl">
                {{ item.shortUrl }}
              </a>
            </div>
            <div
              class="btn"
              v-clipboard:copy="item.shortUrl"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
            >
              复制
            </div>
          </div>
          <template v-if="[3].includes(item.gcShape)">
            <div class="item" v-show="item.cardNo">
              <div class="label">卡号：{{ item.cardNo }}</div>
              <div
                class="btn"
                v-clipboard:copy="item.cardNo"
                v-clipboard:success="copySuccess"
                v-clipboard:error="copyError"
              >
                复制
              </div>
            </div>
            <div class="item">
              <div class="label">
                短链：
                <a class="text" :href="item.shortUrl">{{ item.shortUrl }}</a>
              </div>
              <div
                class="btn"
                v-clipboard:copy="item.shortUrl"
                v-clipboard:success="copySuccess"
                v-clipboard:error="copyError"
              >
                复制
              </div>
            </div>
            <div class="item">
              <div class="label">密码：{{ item.verifyCode }}</div>
              <div
                class="btn"
                v-clipboard:copy="item.verifyCode"
                v-clipboard:success="copySuccess"
                v-clipboard:error="copyError"
              >
                复制
              </div>
            </div>
          </template>
        </li>
      </ul>
    </div>
    <div class="wrapper-rule">
      <h1>使用说明</h1>
      <div class="content" v-html="checkData.instructions" />
    </div>
  </div>
</template>

<script>
import {
  checkForSyncLink,
  quickSyncVerify,
  quickSyncList,
  addRecord
} from '@/api/QuickSyncLink.js'
export default {
  data() {
    return {
      checkData: {},
      list: [],
      loading: false,
      couponStatus: null // 4:已冻结，5：已使用，6：已过期, 95899:缺货显示异常
    }
  },
  mounted() {
    this.getCheckFlag()
    this.doRecord(0)
  },
  methods: {
    async getCheckFlag() {
      const { data, code } = await checkForSyncLink({
        pageTypeMatchFlag: true,
        redeCodeNoPwd: this.$route.params.code,
        redeemCodePageType: 7
      })
      this.checkData = data
      if ([4, 5, 6].includes(code)) {
        this.couponStatus = code
        return
      }
      if (data?.token) {
        this.getList(data.redeCodeNo, data.token)
      }
    },
    async doExchange() {
      try {
        this.loading = true
        const { code, data, message } = await quickSyncVerify({
          cipher: this.$route.params.code,
          redeemToken: this.checkData?.redeemToken
        })
        this.loading = false
        if ([0].includes(code)) {
          this.getList(data.redeCodeNo, data.token)
          this.doRecord(1)
        } else if ([2].includes(code)) {
          // 缺货显示异常
          this.couponStatus = 95899
        } else {
          this.$toast({
            message: message
          })
        }
      } catch (error) {
        this.loading = false
      }
    },
    async getList(redeCodeNo, token) {
      const { code, data } = await quickSyncList({
        redeCodeNo: redeCodeNo,
        token: token
      })
      if (code === 0) {
        this.list = data?.outStorageDetailsVOList || []
      }
    },
    async doRecord(val) {
      // val操作类型：0-打开；1-兑换；2-复制；
      await addRecord({ cipher: this.$route.params.code, operateType: val })
    },
    copySuccess() {
      this.doRecord(2)
      this.$toast({
        message: '复制成功'
      })
    },
    copyError() {
      this.$toast({
        message: '复制失败'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  margin: 20px 38px;
  &-good {
    background: #ffffff;
    border-radius: 20px;
    padding: 32px 25px 60px;
    & > img {
      width: 100%;
      max-width: 500px;
      margin: auto;
      display: block;
    }
    .line {
      border-bottom: dashed 2px #dbdbdb;
      margin: 33px 0 40px;
      position: relative;
      &::before {
        position: absolute;
        background: #f3f3f3;
        width: 30px;
        height: 30px;
        top: -15px;
        left: -38px;
        border-radius: 50%;
        content: '';
      }
      &::after {
        position: absolute;
        background: #f3f3f3;
        width: 30px;
        height: 30px;
        top: -15px;
        right: -38px;
        border-radius: 50%;
        content: '';
      }
    }
    .couponStatus {
      position: relative;
      .buttons {
        width: 90%;
        height: 80px;
        line-height: 80px;
        text-align: center;
        margin: 0 auto 39px;
        background: #3286dd;
        border-radius: 40px;
        font-size: 32px;
        color: #ffffff;
        display: block;
      }
      .active {
        background: #ced2d5;
      }
      & > p {
        font-size: 24px;
        color: #808080;
        text-align: center;
      }
      .expire {
        position: absolute;
        top: -90px;
        right: 0;
        width: 157px;
        line-height: 60px;
        text-align: center;
        border-radius: 8px;
        border: 5px solid #c4382a;
        box-sizing: border-box;
        font-size: 32px;
        color: #c53b2d;
        rotate: -10deg;
        font-weight: bold;
      }
      .fail {
        font-size: 24px;
        color: #808080;
        text-align: center;
        & > img {
          width: 122px;
          margin: 0 auto 10px;
        }
      }
    }
    .list {
      margin: 0 10px;
      .item {
        display: flex;
        justify-content: space-between;
        line-height: 1;
        align-items: center;
        margin-top: 41px;
        .label {
          font-size: 26px;
          color: #323234;
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .btn {
          width: 120px;
          height: 60px;
          background: #3286dd;
          border-radius: 30px;
          text-align: center;
          line-height: 60px;
          padding: 0 12px;
          margin-left: 21px;
          font-size: 26px;
          color: #ffffff;
        }
      }
    }
  }
  &-rule {
    background: #fff;
    border-radius: 20px;
    padding: 50px 48px;
    margin-top: 30px;
    font-size: 26px;
    line-height: 1.5;
    color: #808080;
    & > h1 {
      font-size: 34px;
      color: #000000;
      font-weight: 500;
      display: flex;
      flex-direction: row;
      padding: 0px 0px 40px;
      row-gap: 40px;
      column-gap: 40px;
      &::before,
      &::after {
        content: '';
        flex: 1 1;
        border-bottom: solid 2px #eeeeee;
        margin: auto;
      }
    }
  }
  .omit {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
